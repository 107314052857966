import React, { Component } from "react";
import styled from "styled-components";
import { countDaysTilElection, getStories } from "./utils";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";

const daysTilElection = countDaysTilElection();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stories: []
    };
  }

  componentDidMount() {
    getStories().then(result => {
      this.setState({
        loading: false,
        stories: result
      });
    });
  }

  render() {
    return (
      <AppContainer>
        <Count>
          <Number>{daysTilElection}</Number>
          <Days>Days</Days>
        </Count>
        <Headlines>
          {this.state.loading ? (
            <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Spinner />
            </div>
          ) : (
            <>
              <b>Latest CBS News political headlines</b>
              {this.state.stories.items.map(story => {
                return (
                  <Story>
                    <Headline target="_blank" href={story.link}>
                      {story.title}
                    </Headline>
                    <Byline>{story.contentSnippet}</Byline>
                  </Story>
                );
              })}
            </>
          )}
        </Headlines>
      </AppContainer>
    );
  }
}

const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const Number = styled.div`
  font-size: 10em;
`;

const Days = styled.div`
  font-size: 2em;
`;

const Headlines = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
`;

const Story = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10px;
`;

const Headline = styled.a`
  text-align: left;
  &:hover {
    opacity: 0.6;
  }
`;

const Byline = styled.span`
  font-size: 0.7rem;
`;

export default App;
