import Parser from "rss-parser";

export const countDaysTilElection = () => {
  const today = new Date();
  const electionDay = new Date(today.getFullYear(), 10, 3);
  var one_day = 1000 * 60 * 60 * 24;
  return Math.floor((electionDay.getTime() - today.getTime()) / one_day) + 1;
};

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

export const getStories = async () => {
  let parser = new Parser();
  let feed = await parser.parseURL(
    CORS_PROXY + "https://www.cbsnews.com/latest/rss/politics"
  );
  return feed;
};
